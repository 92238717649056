export default [
    {
        id:1,
        name:"Los Angeles"
    },
    {
        id:2,
        name:"San Diego"
    },
    {
        id:3,
        name:"San Fransisco"
    },
    {
        id:4,
        name:"Las Vegas"
    }
]